import React, { useContext, useState } from 'react'
import termsAndConditionsStyles from './termsAndConditionsStyles'
import { AuthContext } from '../../utilities/contexts/AuthContext'
import { TermsAndConditionsService } from '../../utilities/services/TermsAndConditionsService'
import { Box, Button, Container, Grid, Typography, CircularProgress } from '@mui/material'
import NapaLogo from '../../assets/napa_logo.png'
import ApacLogo from '../../assets/apac_logo.svg'
import { useTerms } from '../../queries/Terms/useTerms'
import language from '../../language/language'
import { LanguageContext } from '../../utilities/contexts/LanguageContext'
import { IAuthContext,AuthContext as ApacAuthContext } from 'react-oauth2-code-pkce'
import { isApac } from '../../utilities/helpers/RegionHelper'

const TermsAndConditions = () => {
  const { currentUser, signOut, refetchTerms } = useContext(AuthContext)
  const { loginInProgress, logOut } = useContext<IAuthContext>(ApacAuthContext)
  const { data: terms, isLoading: isLoadingTerms } = useTerms()
  const { currentLanguage } = useContext(LanguageContext)
  
  const [acceptLoading, setAcceptLoading] = useState<boolean>(false)
  const termsBody = terms?.body?.replaceAll('\\n', '\n')

  const handleAccept = async () => {
    if (acceptLoading) return
    if (currentUser && terms) {
      try {
        setAcceptLoading(true)
        await TermsAndConditionsService.saveUserTermsHistory(
          currentUser.username,
          terms.version
        )
        refetchTerms()
      } catch (e: any) {
        console.error('Error saving user term history', e.message)
      }
      setAcceptLoading(false)
    }
  }

  const handleDecline = async () => {
    isApac ? logOut() : await signOut()
  }

  const HeaderBar = () => {
    return (
      <Box sx={termsAndConditionsStyles.termsHeaderBar}>
        <img src={isApac ? ApacLogo : NapaLogo} style={termsAndConditionsStyles.napaLogo} alt={isApac ? "GPC_logo" : "Napa_logo"} />
        <Typography sx={termsAndConditionsStyles.headerBarTypography}>{isApac ? 'Genuine Delivery' : 'DELIVERY TRACKER'}</Typography>
      </Box>
    )
  }

  if (isLoadingTerms || loginInProgress) return <div>Loading</div>
  return (
    <Grid>
      <HeaderBar />
      <Container>
        <Grid sx={termsAndConditionsStyles.headerContainer}>
          <Typography sx={termsAndConditionsStyles.headerText}>{(language as any)[currentLanguage].termsAndConditions}</Typography>
          <Typography sx={termsAndConditionsStyles.subHeaderText}>
          {(language as any)[currentLanguage].lastUpdated}
          </Typography>
        </Grid>

        <Container sx={termsAndConditionsStyles.container}>
          <Grid sx={termsAndConditionsStyles.tcContainer}>
            <Typography sx={termsAndConditionsStyles.tcP}>
              {termsBody}
            </Typography>
          </Grid>
        </Container>
        <Grid sx={termsAndConditionsStyles.buttonGroup}>
          <Button onClick={handleDecline} sx={termsAndConditionsStyles.declineButton}>
          {(language as any)[currentLanguage].decline}
          </Button>
          <Button
            data-testid="accept-terms"
            sx={termsAndConditionsStyles.acceptButton}
            startIcon={acceptLoading ? <CircularProgress size={20} /> : null}
            onClick={handleAccept}
          >
            {(language as any)[currentLanguage].accept}
          </Button>
        </Grid>
      </Container>
    </Grid>
  )
}

export default TermsAndConditions
